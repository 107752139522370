import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ExternalLink from '../components/externalLink';

const AboutPage = () => (
  <Layout>
    <SEO title="Silver Round" />
    <h2>Pacific Northwet 🌧 Silver Round</h2>
    <p align="center">
      <img src="/silver/front.jpg" alt="front of round" width="400"/>
      <h3>Celebrate the PNW with this unique silver round!</h3>
    </p>
    <p>
      Our unique Pacific Northwet design is now available in precious metal form, for the first time!<br/>
      Presented as a 1 troy ounce 99.9% pure silver round, highlighting one of the most-known features of the Pacific North West &mdash; constant rain 🌧.<br/>
    </p>
    <p>
      Every round is individually struck by our talented friend at <ExternalLink href="https://www.etsy.com/shop/PNWSilverCo" title="PNW Silver">PNW Silver</ExternalLink>.<br/>
      Each has a slightly different shape, and personality. 
    </p>
    <p>
      <h4>Specifications</h4>
      <h6>Front</h6>
      <img src="/silver/front.jpg" alt="front of round" width="300"/>
      <br/>
      The front of the round features 2 rain clouds, with a mirror finish on the left cloud.
    </p>
    <p>
      <h6>Motto</h6>
      <img src="/silver/motto.jpg" alt="motto round" width="300"/>
      <br/>
      The motto is carefully engraved around the top of the clouds.
    </p>
    <p>
      <h6>Back</h6>
      <img src="/silver/back.jpg" alt="back of round" width="300"/>
      <br/>
      The back of the round features the following details:
      <ul>
        <li>"1 OZT" - Weight: certifies the round weighs 1 troy ounce.</li>
        <li>"999FS" - Purity: certifies the round is made from 99.9% Fine Silver.</li>
        <li>"1/20" - Serial: round 1 out of 20. This number will be differnt for every round.</li>
        <li>"Batch 1" - currently the only batch of rounds struck. every batch is limited to 20 rounds.</li>
        <li>The mint mark of <ExternalLink href="https://www.etsy.com/shop/PNWSilverCo" title="PNW Silver">PNW Silver</ExternalLink>.</li>
      </ul>
    </p>
    <p>
      <h5>Certificate of Authenticity</h5>
      <img src="/silver/coa.jpg" alt="certificate of authenticity" width="300"/>
      <br/>
      Each round is accompanied by a hand-written certificate, attesting to the weight, purity, pour date, and serial number of the round.
    </p>
    <p>
      <h5>Silver Facts</h5>
      <ul>
        <li>Silver is a precious metal, that has been used for thousands of years for jewlery and commerce.</li>
        <li>Silver weight is measured in Troy Ounces (TOZ) which are slightly heavier than the ounce you know (1 TOZ = 1.0971 "regular" ounce).</li>
        <li>Silver melt value, also known as "spot price" changes constantly, and dictates the minimum value you'd get per TOZ.</li>
        <li>A silver "round" is a coin-shaped slab of silver, usually featuring art, or a message.<br/>
        It's called a round rather than a coin, since it has no monetary value assigned by a government.</li>
        <li>The value of a round is dictated by its scarcity (how many are available) and art quality.</li>
      </ul>
    </p>
    <p>
      <h3>How to buy one of these amazing rounds?</h3>
      Currently, this round is sold on Reddit only. If you're interested in one, log in to your Reddit account (or create one - it's free and anonymous),<br/>
      Then, hit <big style={{background: 'yellow'}}><ExternalLink href="https://www.reddit.com/message/compose/?to=Niceguy955" target="_blank" title="Buy on Reddit">this link</ExternalLink></big> and leave a message saying you want to buy one.
      You will be contacted back with payment and shipping details.<br/>
      <br/>
      The price of a single round is <big><b>$48.99 + shipping</b></big> (will be calculated by your zip code - usually $4 in Continental US).

    </p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default AboutPage
